import emitter from "../events/config";
import { createCallbackConversation, getConversationData, getRes1000URLData, setConversationData } from "../services/conversation";
import { setUserPresence } from "../services/users";

const queueId = `${process.env.REACT_APP_QUEUE_ID}`;
const presenceIdIn = `${process.env.REACT_APP_SET_QUEUE_ID}`;
const presenceIdOut = `${process.env.REACT_APP_REMOVE_QUEUE_ID}`;

var urlAttributes = undefined;

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const clearLocalStorage = async () => {
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('flowCalled');
}

export const getUrlParameters = async () => {
    console.log('### Iniciado getUrlParameters');
    if (urlAttributes === undefined) {
        console.log('### getUrlParameters: indo para setUrlParameters');
        await setUrlParameters();
    }

    console.log('### getUrlParameters urlAttributes', urlAttributes);
    console.log('### Finalizado getUrlParameters');
    return urlAttributes;
}

export const setUrlParameters = async () => {
    console.log('### Iniciado setUrlParameters');
    var attr = await getRes1000URLData();
    urlAttributes = attr;

    console.log('### setUrlParameters urlAttributes: ', urlAttributes);
    console.log('### Finalizado setUrlParameters');
    return urlAttributes;
}

export const executeCallNow = async (callbackNumbers) => {
    await createCallbackConversation(callbackNumbers, queueId);
}

export const setAgentInQueue = async () => {
    console.log('### Response: Iniciando setAgentInQueue');

    let userId = sessionStorage.getItem('userId');
    await setUserPresence(userId, presenceIdIn);

    console.log('### Response: Finalizando setAgentInQueue');
}

export const removeAgentInQueue = async () => {
    console.log('### Response: Iniciando removeAgentInQueue');

    let userId = sessionStorage.getItem('userId');
    await setUserPresence(userId, presenceIdOut);

    console.log('### Response: Finalizando removeAgentInQueue');
}

export const eventsListenerStart = async (event) => {
    console.log('### event start', event)
}

export const eventsListener = async (event) => {
    const dataParse = JSON.parse(event.data);
    const dataTopic = dataParse.eventBody.participants;
    console.log('### eventsListener dataParse ', dataParse)
    console.log('### eventsListener dataTopic', dataTopic);

    // caso não tiver o topico.
    if (!dataTopic) { return; }

    await setConversationData();
    const attaches = await getConversationData();
    console.log('### attaches eventsListener ', attaches);
    console.log('### attaches eventsListener dt_call_transferencia', attaches["dt_call_transferencia"]);
    console.log('### attaches eventsListener monitoredParticipantId ', attaches["monitoredParticipantId"]);

    // chamada encerrada via tranferencia.
    if (attaches["dt_call_transferencia"]) { return; }

    for (let i = 0; i < dataTopic.length; i++) {
        console.log('### eventsListener dataTopic element ', dataTopic[i]);
        console.log('### eventsListener disconnectType ', dataTopic[i].disconnectType);
        console.log('### eventsListener direction ', dataTopic[i].direction);
        console.log('### eventsListener purpose ', dataTopic[i].purpose);
        console.log('### eventsListener name ', dataTopic[i].name);
        console.log('### eventsListener state ', dataTopic[i].state);

        // chamada encerrada para quem está monitorando.
        if (dataTopic[i].monitoredParticipantId) { return; }

        // chamada encerrada para quem está consultando.
        if (dataTopic[i].consultParticipantId) { return; }

        // chamada encerrada com SUPORTE_CEC.
        if (dataTopic[i].name === 'SUPORTE_CEC') { return; }

        // chamada encerrada que acionarão o poopup.
        if (dataTopic[i].direction === "inbound"
            && dataTopic[i].purpose === "agent"
            && dataTopic[i].state === "disconnected") {

            if (dataTopic[i].disconnectType === "peer"
                || dataTopic[i].disconnectType === "transfer"
                || dataTopic[i].disconnectType === "client") {

                emitter.emit('isCallBack', dataTopic[i].disconnectType);
            }
        }

    }
    return;
}

