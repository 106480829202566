import { isAuthenticated, onAjax } from "./auth";
import { getUserData } from "./users";
import { sleep } from "../scripts";

var conversation = undefined;

export const setConversationData = async () => {
    conversation = await getConversationsLoggedUser();
    console.log('### setConversationData inicial', conversation);
    console.log('### setConversationData dt_res1000_agendamento', conversation['dt_res1000_agendamento']);
    if (conversation['dt_res1000_agendamento']) {
        conversation = await getPastConversationByID(conversation['dt_res1000_chamada_anterior']);
    }
    console.log('### setConversationData final', conversation);
}

export const getConversationData = async () => {
    console.log('### getConversationData iniciado');
    console.log('### getConversationData conversation', conversation);
    console.log('### getConversationData conversation typeof', typeof conversation);
    console.log(`### getConversationData conversation === undefined: ${conversation === undefined}`);
    if (conversation === undefined) {
        await setConversationData();
    };

    console.log('### getConversationData conversation', conversation);
    console.log('### getConversationData finalizado');
    return conversation;
};

export const translateConversationData = async () => {
    console.log('### translateConversationData iniciado');
    var data = await getConversationData();
    console.log('### translateConversationData getConversationData', data);
    var returnData = {};

    console.log(`### translateConversationData data['dt_res1000_dados'] !== undefined: ${data['dt_res1000_dados'] !== undefined}`);
    if (data['dt_res1000_dados'] !== undefined) {
        var arrBody = data['dt_res1000_dados'].split(';');

        console.log('### translateConversationData arrBody:', arrBody);
        for (let i = 0; i < arrBody.length; i++) {
            console.log('### translateConversationData arrBody[i] !== "":', arrBody[i] !== "");
            if (arrBody[i] !== "") {
                console.log('### translateConversationData arrBody[i]', arrBody[i]);
                let splited = arrBody[i].split('=');
                console.log('### translateConversationData splited', splited);
                returnData[splited[0]] = splited[1];
            }
        }
    }

    console.log('### translateConversationData returnData', returnData);
    console.log('### translateConversationData finalizado');
    return returnData;
};

export const getRes1000URLData = async (retries = 3) => {
    console.log('### getRes1000URLData iniciado');
    var data = await getConversationData();
    var searchParams = new URLSearchParams();
    var attr = {};

    console.log(`### getRes1000URLData data['dt_res1000_url'] !== undefined: ${data['dt_res1000_url'] !== undefined}`);
    if (data['dt_res1000_url'] !== undefined) {
        console.log('### getRes1000URLData dt_res1000_url ', data['dt_res1000_url']);
        searchParams = new URL(data['dt_res1000_url']).searchParams;
    } else {
        await setConversationData();
        data = await getConversationData();
        console.log('### getRes1000URLData retries', retries)
        if (data['dt_res1000_url'] === undefined && retries > 0) {
            await sleep(1500);
            await getRes1000URLData(--retries);
        } else {
            console.log('### getRes1000URLData dt_res1000_url else', data['dt_res1000_url']);
            searchParams = new URL(data['dt_res1000_url']).searchParams;
        }
    }

    searchParams.forEach((value, key) => {
        console.log(`### getRes1000URLData searchParams: ${key} ${value}`);
        attr[key] = value;
    });

    console.log('### getRes1000URLData attr: ', attr);
    console.log('### getRes1000URLData Object.keys(attr).length > 0: ', Object.keys(attr).length > 0);
    console.log('### getRes1000URLData finalizado');

    if (Object.keys(attr).length > 0) {
        return attr;
    } else {
        return undefined;
    }
}

export const getCallbackData = async () => {
    console.log(`### getCallbackData iniciado`);
    const data = JSON.parse(conversation);
    console.log(`### getCallbackData data do getConversationsLoggedUser: ${JSON.stringify(data)}`);
    var hasCallback = false;
    var callbackData = null;

    console.log(`### getCallbackData res1000_callback !== undefined: ${data['res1000_callback'] !== undefined}`);

    if (data['res1000_callback'] !== undefined) {
        hasCallback = true;

        callbackData = data['dt_res1000_dados'];
        console.log(`### getCallbackData getPastConversationByID data: ${callbackData}`);
    }

    console.log(`### getCallbackData retorno hasCallback: ${hasCallback}`);
    console.log(`### getCallbackData retorno callbackData: ${callbackData}`);
    console.log(`### getCallbackData finalizado`);
    return { hasCallback, callbackData };
}

export const getConversationAttributes = async (participants) => {
    var attr = {};
    // valido se os participantes possuem atributos pois no retorno vem diversos dados repetidos e varia pelo cenario qual possue ou nao atributos
    for (let i = 0; i < participants.length; i++) {
        const element = participants[i]

        if (Object.keys(element.attributes).length === 0) { continue; }

        console.log('### getConversationAttributes element attributes: ', element.attributes);

        if (element.attributes.hasOwnProperty('dt_res1000_url')) {
            console.log('### getConversationAttributes element dt_res1000_url: ', element.attributes);
            attr['dt_res1000_url'] = element.attributes['dt_res1000_url'];
        }

        if (element.attributes.hasOwnProperty('dt_res1000_dados')) {
            console.log('### getConversationAttributes element dt_res1000_dados: ', element.attributes);
            attr['dt_res1000_dados'] = element.attributes['dt_res1000_dados'];
        }

        if (element.attributes.hasOwnProperty('dt_res1000_agendamento')) {
            console.log('### getConversationAttributes element dt_res1000_agendamento: ', element.attributes);
            attr['dt_res1000_agendamento'] = element.attributes['dt_res1000_agendamento'];
        }

        if (element.attributes.hasOwnProperty('dt_res1000_chamada_anterior')) {
            console.log('### getConversationAttributes element dt_res1000_chamada_anterior: ', element.attributes);
            attr['dt_res1000_chamada_anterior'] = element.attributes['dt_res1000_chamada_anterior'];
        }

        if (element.attributes.hasOwnProperty('dt_call_transferencia')) {
            console.log('### getConversationAttributes element dt_call_transferencia: ', element.attributes);
            attr['dt_call_transferencia'] = element.attributes['dt_call_transferencia'];
        }

        if (element.attributes.hasOwnProperty('monitoredParticipantId')) {
            console.log('### getConversationAttributes element monitoredParticipantId: ', element.attributes);
            attr['monitoredParticipantId'] = element.attributes['monitoredParticipantId'];
        }
    }

    if (attr['dt_res1000_agendamento'] === 'SIM') {
        attr['dt_res1000_agendamento'] = true;
    } else {
        attr['dt_res1000_agendamento'] = false;
    }

    console.log('### getConversationAttributes attr: ', attr);
    if (Object.keys(attr) !== 0) return attr;
    return undefined
}

export const getPastConversationByID = async (conversationId) => {
    try {
        const response = await onAjax('GET', `conversations/calls/${conversationId}`)
        console.debug(`### Response: ${response}`);
        const participants = response.participants;
        return await getConversationAttributes(participants);
    } catch (error) {
        console.log('### getConversationsLoggedUser error: ', error);
        // removo o client id para cair na condição para renovar token e refazer a solicitaçao
        sessionStorage.removeItem("clientId");
        isAuthenticated();
    }
}

export const getConversationsByID = (conversationId) => { onAjax('GET', `analytics/conversations/${conversationId}/details`) };

export const getConversationsLoggedUser = async () => {
    try {
        const response = await onAjax('GET', `conversations`)
        console.log('### getConversationsLoggedUser GET response: ', JSON.stringify(response));
        const participants = response.entities[0].participants;
        return await getConversationAttributes(participants);
    } catch (error) {
        console.log('### getConversationsLoggedUser error: ', error);
        // removo o client id para cair na condição para renovar token e refazer a solicitaçao
        sessionStorage.removeItem("clientId");
        isAuthenticated();
    }
}

export const createCallbackConversation = async (telefoneRetorno) => {
    // /api/v2/conversations/${input.conversationId}/participants/${input.participantId}/attributes
    var urlData = await getRes1000URLData();
    var userData = await getUserData();
    var userId = userData.id;

    var conversationId = urlData['conversationId'];
    var participantIdCliente = urlData['participantIdCliente'];

    var body = {
        "attributes": {
            "RETORNO_RES1000": `SIM;${userId}`,
            "dt_call_ani_retorno": telefoneRetorno
        }
    }

    const response = await onAjax('PATCH', `conversations/${conversationId}/participants/${participantIdCliente}/attributes`, body);
    console.log("### createCallbackConversation PATCH response: ", response);

}
